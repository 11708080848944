<template>
    <div class="footer">
        <img class="footer__logo" :src="require('../assets/images/logo.svg')" alt="Lagerwunder" />
        <div class="footer__links">
            <a v-for="link in links" :key="link.label" class="footer__link" :href="link.url" target="_blank">{{ link.label
            }}</a>
        </div>
    </div>
</template>
<script>
import { FOOTER_LINKS } from '@/config/constants';

export default {
    data() {
        return {
            links: FOOTER_LINKS
        }
    }
}
</script>