<template>
  <ProgressBar :stage="1" />
  <MainContainer :showContent="rendered" :showLoader="true">
    <form v-if="data" class="form" @submit.prevent="onSubmit">
      <h1>Wähle dein Lager aus</h1>
      <div class="step1__storages">
        <StorageCard v-for="item in data" :key="item.storageId" :storage="item" :onClick="() => storageClick(item)"
          :disableTabindex="isModal" />
      </div>
      <FormButtons :leftButton="{ label: 'Zurück', icon: true, onClick: back }"
        msg="Wähle ein Lager aus der Liste aus, um fortzufahren" :disableTabindex="isModal" />
    </form>
    <CommonFailScreen v-if="!data"
      msg="Wir konnten keine verfügbaren Speicherorte finden, die Ihren Kriterien entsprechen"
      buttonLabel="Versuchen Sie es mit anderen Kriterien" :buttonOnClick="back" imageVariant="storageSearchFail" />
  </MainContainer>
  <FooterMain />
  <Transition name="modal">
    <PricingModal v-if="isModal" :storage="storage" :onClose="closeModal" />
  </Transition>
</template>

<script>
import CommonFailScreen from "@/components/CommonFailScreen.vue";
import FormButtons from "@/components/FormButtons.vue";
import MainContainer from "@/components/MainContainer.vue";
import PricingModal from "@/components/PricingModal.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import StorageCard from "@/components/StorageCard.vue";
import store from "@/store";
import { RouterPaths } from "@/router/index";
import FooterMain from "@/components/FooterMain.vue";

export default {
  name: 'Step1_Box',
  components: {
    ProgressBar,
    FormButtons,
    StorageCard,
    PricingModal,
    MainContainer,
    CommonFailScreen,
    FooterMain
  },
  methods: {
    back() {
      store.actions.saveStateLocally();
      this.$router.push(RouterPaths.BOX_SEARCH);
    },
    storageClick(storage) {
      this.storage = storage;
      if (this.storage) this.isModal = true;
    },
    closeModal() {
      this.isModal = false;
    }
  },
  data() {
    return {
      isModal: false,
      storage: store.state.storage,
      rendered: false,
      data: {}
    };
  },
  mounted() {
    const payload = {
      areaFrom: store.state.areaFrom,
      areaTo: store.state.areaTo,
      dateFrom: store.state.date,
      type: store.state.storageType,
      locationId: store.state.locationId,
      storageType: store.state.storageType,
      demo: store.state.demo
    };
    this.axios
      .get(process.env.VUE_APP_API_BASE_URL + '/storage', { params: payload })
      .then(res => {
        const storages = res.data.storages;
        storages.sort((a, b) => a.priceMonthly - b.priceMonthly);
        this.data = storages.length > 0 ? storages : null;
        if (storages.length > 0 && storages.findIndex(el => el.storageId === this.storage?.storageId) === -1) {
          this.storage = null;
          store.actions.saveStorage(null);
        }
      })
      .catch(err => {
        this.$router.push(RouterPaths.ERROR);
        console.log(err)
      })
      .finally(() => {
        this.rendered = true;
      })
  }
}
</script>
