// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/arrow-left-orange.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/arrow-right-white.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@use \"../colors.css\";.buttons-container{margin-top:2rem;display:flex;align-items:center;justify-content:space-between;gap:10px}.prime-button,.sec-button{padding:.75rem 1.5rem;border-radius:.75rem;cursor:pointer;font-size:1rem;line-height:1.5rem;transition-duration:.3s;align-self:stretch;justify-content:center;flex:1}.sec-button{border:1px solid var(--gray200);background-color:var(--white);color:var(--primary600)}.prime-button{border:1px solid var(--primary600);background-color:var(--primary600);color:var(--white)}.prime-button--icon,.sec-button--icon{display:flex;align-items:center;gap:.5rem}.sec-button--icon:before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:1.5rem;height:1.5rem}.prime-button--icon:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");width:1.5rem;height:1.5rem}.buttons-msg{margin-left:1rem}@media (min-width:768px){.prime-button,.sec-button{flex:unset}}@media (hover:hover){.sec-button:hover{background-color:var(--gray50)}.prime-button:hover{border-color:var(--primary700);background-color:var(--primary700)}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
