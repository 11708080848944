<template>
    <ProgressBar :stage="4" />
    <BookingTimer />
    <MainContainer :showContent="rendered" :showLoader="true" class="step4__main">
        <div class="step4__main__container">
            <h1 v-if="!isLoading">Bitte gib deine Zahlungsdetails an</h1>
            <div class="step4__form-container" v-show=!isLoading>
                <form id="payment-form" class="form step4__form">
                    <div id="payment-element">
                        <!-- Elements will create form elements here -->
                    </div>
                    <div id="error-message">
                        <!-- Display error message to your customers here -->
                    </div>
                </form>
                <div class="step4__storage-card">
                    <h2 class="step4__storage-card-title">Buchungsübersicht</h2>
                    <p class="step4__storage-card-info">Ausgewähltes Lager:<span>{{ storage.name }}</span></p>
                    <p class="step4__storage-card-info">B x T x H:<span>{{ storage.width }}m x {{ storage.depth }}m x {{
                        storage.height
                    }}m</span></p>
                    <p class="step4__storage-card-info">Volumen:<span>{{ storage.volume }}m<sup>3</sup></span></p>
                    <p class="step4__storage-card-info">Fläche:<span>{{ storage.area }}m<sup>2</sup></span></p>
                    <p class="step4__storage-card-info">Stockwerk:<span>{{ storage.floor }}</span></p>
                    <p v-if="storage.type === 'Garage'" class="step4__storage-card-info">Beheizt:<span>{{ isHeatedText
                    }}</span></p>
                    <p v-if="storage.isEnergyConsumption && storage.type === 'Garage'" class="step2__storage-card-info">
                        Inkl. Stromverbrauch/Jahr:<span>{{
                            storage.energyConsumptionLimit }}kWh</span></p>
                    <p class="step4__storage-card-consumptionInformation"
                        v-if="storage.isEnergyConsumption && storage.type === 'Garage'">Zusätzlicher Stromverbrauch
                        wird verbrauchsabhängig abgerechnet</p>
                    <p class="step4__storage-card-info">Bezugstermin:<span>{{ startingDate }}</span></p>
                    <p class="step4__storage-card-info">Einmalige Kaution:<span>{{ storage.priceDeposit }}€</span></p>
                    <p class="step4__storage-card-info">{{ pricingPeriodLabel }}
                        Miete:<span>{{ price.toFixed(2) }}€</span></p>
                    <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step4__storage-card-info">
                        Mehrwertsteuer
                        ({{ storage.vat }}%):<span>{{ vat.toFixed(2) }}€</span></p>
                    <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step4__storage-card-info">{{
                        pricingPeriodLabelTotalPrice }}
                        Gesamtpreis:<span>{{ totalPrice.toFixed(2) }}€</span></p>
                    <p class="step4__storage-card-info">Gesamtpreis:<span class="step4__storage-card-price">{{
                        totalPriceWithDeposit.toFixed(2) }}€</span></p>
                </div>
            </div>
            <FormButtons v-if="!isLoading" :leftButton="{ label: 'Zurück', icon: true, onClick: back, disabled: isLoading }"
                :rightButton="{ label: 'Bezahlen', icon: true, onClick: next, disabled: isLoading }" />
        </div>
    </MainContainer>
    <FooterMain />
    <LoaderComponent v-if="isLoading" />
</template>

<script>
import BookingTimer from "@/components/BookingTimer.vue";
import FormButtons from "@/components/FormButtons.vue";
import MainContainer from "@/components/MainContainer.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { PRICING_PERIOD_TO_LABEL_MAP_RENT, PRICING_PERIOD_TO_LABEL_MAP_TOTAL_PRICE, STORAGE_BOOKING_STAGES, STRIPE_PUBLISHKEY_BY_LOCATIONID_MAP } from "@/config/constants";
import store from "@/store";
import { nextTick } from 'vue';
import moment from 'moment';
import { calculateVAT, calculatePrice } from "../utils/price";
import { RouterPaths, getPathWithLocationName } from "@/router/index";
import FooterMain from "@/components/FooterMain.vue";

export default {
    name: 'Step4_Payment',
    components: {
        ProgressBar,
        FormButtons,
        MainContainer,
        BookingTimer,
        LoaderComponent,
        FooterMain
    },
    methods: {
        back() {
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.CONTRACT)
            store.actions.saveStateLocally();
            this.$router.push(getPathWithLocationName(RouterPaths.CONTRACT, this.locationName));
        },
        async next() {
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.SUMMARY)
            store.actions.saveStateLocally();
            this.isLoading = true;
            await this.pay();
            this.isLoading = false;
        },
        async pay() {
            const { error } = await this.stripe.confirmPayment({
                elements: this.elements,
                confirmParams: {
                    return_url: window.location.origin + getPathWithLocationName(RouterPaths.SUMMARY, encodeURIComponent(this.locationName)),
                }
            });
            if (error) {
                store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.PAYMENT)
                const messageContainer = document.querySelector('#error-message');
                messageContainer.textContent = error.message;
            }
        },
        async loadStripeElements() {
            const stripePriceIdMap = {
                week: this.storage.stripeIdPriceWeekly,
                month: this.storage.stripeIdPriceMonthly,
                year: this.storage.stripeIdPriceYearly
            }
            const stripePriceId = stripePriceIdMap[this.pricingPeriod];

            const payload = {
                email: store.state.user.email,
                stripePriceId: stripePriceId,
                entitledToDeduction: this.userData.entitledToDeduction,
                storageType: this.storageType,
                stripeVatId: this.storage.stripeIdVAT,
                stripeDepositId: this.storage.stripeIdPriceDeposit,
                sfBookingId: this.sfBookingId,
                authId: store.state.authId,
                locationId: store.state.locationId,
                bookingStartDate: moment(store.state.date).unix(),
            };
            try {
                const response = await this.axios
                    .post(process.env.VUE_APP_API_BASE_URL + '/payment', payload);

                this.rendered = true;
                await nextTick();

                const appearance = {
                    theme: 'stripe',

                    variables: {
                        colorPrimary: '#ba481b',
                        colorText: '#30313d',
                    }
                };

                const options = {
                    clientSecret: response.data.clientSecret,
                    appearance: appearance,
                    locale: "de"
                }
                this.elements = this.stripe.elements(options);
                const paymentElement = this.elements.create('payment');
                paymentElement.mount('#payment-element');
            }
            catch (err) {
                this.$router.push(RouterPaths.ERROR)
            }
        }
    },
    mounted() {
        this.stripe = window.Stripe(STRIPE_PUBLISHKEY_BY_LOCATIONID_MAP[store.state.locationId]);
        this.loadStripeElements();
    },
    data() {
        return {
            isLoading: false,
            stripe: null,
            elements: null,
            rendered: false,
            storage: store.state.storage,
            locationName: store.state.locationName,
            storageType: store.state.storageType,
            startingDate: store.state.date,
            pricingPeriod: store.state.pricingPeriod,
            pricingPeriodLabel: PRICING_PERIOD_TO_LABEL_MAP_RENT[store.state.pricingPeriod],
            pricingPeriodLabelTotalPrice: PRICING_PERIOD_TO_LABEL_MAP_TOTAL_PRICE[store.state.pricingPeriod],
            userData: { ...store.state.user },
            sfBookingId: store.state.sfBookingId
        }
    },
    computed: {
        price() {
            //TODO: Move this logic to backend
            const priceMap = {
                week: this.storage.priceWeekly,
                month: this.storage.priceMonthly,
                year: this.storage.priceYearly
            }
            return priceMap[this.pricingPeriod];
        },
        vat() {
            return calculateVAT(this.storage.vat, this.price);
        },
        totalPrice() {
            return calculatePrice(this.price, this.storage.vat, this.userData.entitledToDeduction || this.storageType === "Garage", 0)
        },
        totalPriceWithDeposit() {
            return calculatePrice(this.price, this.storage.vat, this.userData.entitledToDeduction || this.storageType === "Garage", this.storage.priceDeposit)
        },
        isHeatedText() {
            return this.storage.heated ? 'ja' : 'nein';
        },
    },
}
</script>
