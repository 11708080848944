<template>
  <ProgressBar :stage="0" />
  <MainContainer :showContent="rendered" :show-loader="true">
    <form class="form" @submit.prevent="onSubmit">
      <h1>Nach was für einem Lager suchst du?</h1>
      <h2 class="step0__form-subtitle">Standort</h2>
      <div class="location" :class="{ 'location__big': LOCATIONS.length % 2 == 0 }">
        <label v-for="item in LOCATIONS" :key="item.id" :for="`location${item.id}`" class="location__element"
          :class="{ 'location__element--checked': locationId === item.id, 'location__element__big': LOCATIONS.length % 2 == 0 }"
          :tabindex="0" @keypress.enter="() => { locationId = item.id; }">
          <input v-model="locationId" type="radio" :id="`location${item.id}`" name="location" :value="item.id"
            :tabindex="-1">{{
              item.name
            }}
          <span v-if="checkIsLocationUpcoming(item)" class="location__element__upcoming-badge">{{
            getUpcomingLocationLabel(item.openingDate) }}</span>
        </label>
      </div>
      <h2 class="step0__form-subtitle">Lagertyp</h2>
      <div class="storage-type">
        <label for="storageTypeChoice1" class="storage-type__element storage-type__element--box"
          :class="checkIsDisableStyle('Box')" :tabindex="0" @keypress.enter="() => {
            storageType = 'Box'
          }">
          <input v-model="storageType" type="radio" id="storageTypeChoice1" name="storageType" value="Box" :tabindex="-1"
            :disabled="checkIsDisable('Box')">Box
        </label>
        <label for="storageTypeChoice2" class="storage-type__element storage-type__element--garage"
          :class="checkIsDisableStyle('Garage')" :tabindex="0" @keypress.enter="() => {
            storageType = 'Garage'
          }">
          <input v-model="storageType" type="radio" id="storageTypeChoice2" name="storageType" value="Garage"
            :tabindex="-1" :disabled="checkIsDisable('Garage')">Garage
        </label>
      </div>
      <h2 class="step0__form-subtitle">Größe</h2>
      <div class="storage-size">
        <div class="storage-size__sliders">
          <input v-model.number="areaFrom" type="range" class="storage-size__min" :min="1" :max="60"
            @change="handleAreaFromChange($event)">
          <input v-model.number="areaTo" type="range" class="storage-size__max" :min="1" :max="60"
            @change="handleAreaToChange($event)">
          <div class="storage-size__sliders-filling" :style="getAreaSliderFillingStyle()">
          </div>
        </div>
        <div class="storage-size__inputs">
          <div class="storage-size__input">
            <label for="areaFrom">Min m<sup>2</sup></label>
            <input v-model.number="areaFrom" id="areaFrom" type="number" :min="1" :max="60"
              @change="handleAreaFromChange($event)">
          </div>
          <div class="storage-size__input">
            <label for="areaTo">Max m<sup>2</sup></label>
            <input v-model.number="areaTo" id="areaTo" type="number" :min="1" :max="60"
              @change="handleAreaToChange($event)">
          </div>
        </div>
      </div>
      <h2 class="step0__form-subtitle">Bezugstermin</h2>
      <div class="start-date">
        <p v-if="selectedLocationUpcomingText" class="start-date__upcoming">{{ selectedLocationUpcomingText }}</p>
        <Datepicker input-class-name="datepicker" locale="de" format="EEEE dd/MM/yyyy" :formatLocale="de" v-model="date"
          :minDate="datePickerMinDate" :maxDate="addDays(datePickerMinDate, 28)" :enableTimePicker="false"
          cancelText="Abbrechen" selectText="Auswählen">
        </Datepicker>
      </div>
      <FormButtons :rightButton="{ label: 'Lager suchen', icon: true, onClick: next }" />
    </form>
  </MainContainer>
  <FooterMain />
  <Transition name="modal">
    <UnfinishedBookingModal v-if="showUnfinishedBookingModal" :onClose="closeModal" />
  </Transition>
</template>

<script>
import FooterMain from '@/components/FooterMain.vue';
import FormButtons from '@/components/FormButtons.vue';
import MainContainer from '@/components/MainContainer.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import UnfinishedBookingModal from '@/components/UnfinishedBookingModal.vue';
import { BOOKING_TIMER_INITIAL_VALUE_IN_MILLISECONDS, LOCATIONS, STORAGE_BOOKING_STAGES } from '@/config/constants';
import { RouterPaths, getPathWithLocationName } from "@/router/index";
import store from "@/store";
import Datepicker from '@vuepic/vue-datepicker';
import { de } from 'date-fns/locale';
import moment from 'moment';
import { onMounted, ref } from 'vue';

export default {
  name: 'Step0_Start',
  components: {
    Datepicker,
    ProgressBar,
    FormButtons,
    MainContainer,
    UnfinishedBookingModal,
    FooterMain
  },
  setup() {
    const date = ref();
    onMounted(() => {
      date.value = store.state.date ? moment(store.state.date, 'YYYY-MM-DD').toDate() : new Date();
      if (date.value < new Date()) {
        date.value = new Date()
      }
    })
    return {
      date,
      de
    }
  },
  methods: {
    handleAreaFromChange(ev) {
      if (ev.target.value >= this.areaTo) {
        this.areaFrom = this.areaTo - 1;
        this.$forceUpdate();
        return;
      }
      if (ev.target.value > 60) {
        this.areaFrom = 59;
        this.$forceUpdate();
      }
      if (ev.target.value < 1) {
        this.areaFrom = 1;
        this.$forceUpdate();
      }
    },
    handleAreaToChange(ev) {
      if (ev.target.value <= this.areaFrom) {
        this.areaTo = this.areaFrom + 1;
        this.$forceUpdate();
        return;
      }
      if (ev.target.value > 60) {
        this.areaTo = 60;
        this.$forceUpdate();
      }
      if (ev.target.value < 1) {
        this.areaTo = 2;
        this.$forceUpdate();
      }
    },
    getAreaSliderFillingStyle() {
      const from = this.areaFrom < 1 ? 1 : this.areaFrom;
      const to = this.areaTo > 60 ? 60 : this.areaTo;
      const start = from / 60 * 100 + '%';
      const width = (to - from - 1) / 60 * 100 + '%';
      return { width: width, left: start }
    },
    next() {
      if (!this.locationId || !this.areaFrom || !this.storageType) return;
      store.actions.resetStore();
      store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.UNBEGAN)
      store.actions.saveStorageSearch(this.locationId, this.selectedLocation.name, this.storageType, this.areaFrom, this.areaTo, moment(this.date).format('YYYY-MM-DD'));
      store.actions.saveStateLocally();
      this.$router.push(getPathWithLocationName(RouterPaths.BOX_SELECTION, this.selectedLocation.name));
    },
    calculateBookingTimeLeft() {
      const latestBookingStartDate = store.state.timerStartDate;
      const timeLeft = latestBookingStartDate && BOOKING_TIMER_INITIAL_VALUE_IN_MILLISECONDS - (new Date() - latestBookingStartDate);
      return timeLeft ?? 0;
    },
    checkIsDisable(value) {
      return !this.locationStorageType?.includes(value);
    },
    checkIsDisableStyle(value) {
      const styles = [];
      this.storageType === value && styles.push('storage-type__element--checked');
      !this.locationStorageType?.includes(value) && styles.push('disabled-button');
      return styles.join().replaceAll(',', ' ');
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    checkIsLocationUpcoming(location) {
      if (!location?.isComingSoon || !location?.openingDate) {
        return false;
      }

      const today = moment().endOf('day');
      const date = moment(location.openingDate, "YYYY-MM-DD");

      return date.isAfter(today);
    },
    getUpcomingLocationLabel(openingDate) {
      const formattedDate = moment(openingDate, "YYYY-MM-DD").format("DD.MM.YYYY");
      return `Ab ${formattedDate} beziehbar`
    },
    maybeChangeDate(force = false) {
      const isCorrect = moment(this.date).isBetween(this.datePickerMinDate, this.datePickerMaxDate, null, "[]");
      if (isCorrect && !force) {
        return;
      }
      this.date = this.datePickerMinDate;
    },
    maybeChangeStorageType() {
      const types = this.selectedLocation.storageType.split(';');
      this.locationStorageType = types;
      if (!types.includes(this.storageType)) {
        this.storageType = types[0];
      }
    },
  },
  computed: {
    showUnfinishedBookingModal() {
      const isBookingInProgress = store.state.storageBookingStage !== STORAGE_BOOKING_STAGES.UNBEGAN;
      const minimumTimeToFinishBookingInMilliseconds = 30 * 1000;
      const isBookingTimeLongerThanMinimum = this.calculateBookingTimeLeft() > minimumTimeToFinishBookingInMilliseconds
      return isBookingInProgress && isBookingTimeLongerThanMinimum;
    },
    selectedLocation() {
      return this.LOCATIONS.find(location => location.id === this.locationId);
    },
    selectedLocationUpcomingText() {
      if (!this.checkIsLocationUpcoming(this.selectedLocation)) {
        return null
      }
      const formattedDate = moment(this.selectedLocation.openingDate, "YYYY-MM-DD").format("DD.MM.YYYY");
      return `Das Lager ${this.selectedLocation.name} wird am ${formattedDate} eröffnet. Buchungen sind bereits jetzt möglich.`
    },
    datePickerMinDate() {
      if (!this.checkIsLocationUpcoming(this.selectedLocation)) {
        return new Date();
      }
      return moment(this.selectedLocation.openingDate, "YYYY-MM-DD").toDate();
    },
    datePickerMaxDate() {
      return this.addDays(this.datePickerMinDate, 28);
    }
  },
  data() {
    return {
      rendered: false,
      LOCATIONS: [],
      locationId: store.state.locationId || null,
      locationStorageType: null,
      areaFrom: Number(store.state.areaFrom) || 1,
      areaTo: Number(store.state.areaTo) || 60,
      storageType: store.state.storageType || 'Box',
    };
  },
  watch: {
    locationId() {
      this.maybeChangeDate(true);
      this.maybeChangeStorageType();
    }
  },
  mounted() {
    this.axios
      .get(process.env.VUE_APP_API_BASE_URL + '/location', { params: { authId: store.state.authId } })
      .then(res => {
        const locationIds = res.data.locationData.map(location => location.id);
        this.LOCATIONS = res.data.locationData;
        if (!this.locationId || !locationIds.includes(this.locationId)) {
          this.locationId = this.LOCATIONS[0].id;
          this.locationStorageType = this.LOCATIONS[0].storageType;
        }
      })
      .catch(err => {
        console.log(err);
        if (err.response.status !== 404) {
          this.$router.push(RouterPaths.ERROR);
        }
      })
      .finally(() => {
        this.maybeChangeDate();
        this.maybeChangeStorageType();
        this.rendered = true;
      })
  }
}
</script>
