<template>
    <ProgressBar :stage="4" />
    <MainContainer>
        <CommonFailScreen imageVariant="generic" msg="Es gab ein Problem mit der Zahlung" buttonLabel="Zurück"
            :buttonOnClick="back" />
    </MainContainer>
    <FooterMain />
</template>

<script>
import CommonFailScreen from "@/components/CommonFailScreen.vue";
import FooterMain from "@/components/FooterMain.vue";
import MainContainer from "@/components/MainContainer.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { STORAGE_BOOKING_STAGES } from "@/config/constants";
import { RouterPaths, getPathWithLocationName } from "@/router/index";
import store from "@/store";

export default {
    name: 'Step5_Fail',
    components: {
        ProgressBar,
        MainContainer,
        CommonFailScreen,
        FooterMain
    },
    data() {
        return {
            locationName: store.state.locationName
        }
    },
    methods: {
        back() {
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.PAYMENT);
            store.actions.saveStateLocally();
            this.$router.push(getPathWithLocationName(RouterPaths.PAYMENT, this.locationName));
        }
    }
}
</script>
